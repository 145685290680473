declare module '@mui/material/styles' {
   interface Theme {
      sizes: CustomSizes
   }

   interface ThemeOptions {
      sizes: CustomSizes
   }
}

interface CustomSizes {
   headerSm: number
   sidebarWidth: number
   closedSidebarWidth: number
}

const sizes: CustomSizes = {
   headerSm: 64,
   sidebarWidth: 240,
   closedSidebarWidth: 63,
}

export default sizes

import { useState } from 'react'
import Box from '@mui/material/Box'
import Input from '@mui/material/Input'

import ContainedButton from 'shared/components/Buttons/ContainedButton'
import OutlinedButton from 'shared/components/Buttons/OutlinedButton'
import { useNotificationContext } from 'shared/context/Notifications'
import { useAddFileMutation } from 'shared/store/useComplaints'

interface Props {
   handleClose: () => void
   complaint_id: string
}

export default function AddFileModal({ complaint_id, handleClose }: Props) {
   const { addNotification } = useNotificationContext()
   const [file, setFile] = useState<Blob>()
   const { mutate } = useAddFileMutation()

   function handleFileChange(e: any) {
      if (e.target.files[0]) {
         if (e.target.files[0].size < 1024 * 512) {
            setFile(e.target.files[0])
         }
      }
   }

   function handleSubmit() {
      const formData = new FormData()
      if (file) {
         formData.append('file', file)
      }

      mutate(
         {
            target_uuid: complaint_id,
            file: formData,
         },
         {
            onSuccess: () => {
               addNotification('Plik został dodany', 'success')
               handleClose()
            },
            onError: () => addNotification('Wystąpił błąd', 'error'),
         }
      )
   }

   return (
      <Box>
         <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2, mx: 3, my: 2 }}>
            <Input onChange={(e) => handleFileChange(e)} type="file" />
            <Box sx={{ display: 'flex', gap: 2 }}>
               <OutlinedButton onClick={() => handleClose()}>Zamknij</OutlinedButton>
               <ContainedButton variant="contained" onClick={handleSubmit}>
                  Zapisz
               </ContainedButton>
            </Box>
         </Box>
      </Box>
   )
}

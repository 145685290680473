import React, { useEffect, useRef, useState } from 'react'
import { Routes, Route } from 'react-router-dom'

import PrivateRoute from 'shared/components/PrivateRoute'
import NotificationProvider from 'shared/context/Notifications'
import CustomSnackbar from 'shared/components/CustomSnackbar'

import Layout from './components/Layout'
import Homepage from './views/Homepage'
import Users from './views/Users'
import Tasks from './views/Tasks'
import Complaints from './views/Complaints'
import Assessments from './views/Reports'
import Order from './views/Order'



export default function Authorized() {

   return (
      <NotificationProvider>
         <Routes>
            <Route path="/" element={<Layout />}>
               <Route index element={<Homepage />} />
               <Route path='order' element={<PrivateRoute component={Order}/>}/>
               <Route path='users' element={<PrivateRoute component={Users} />}/>
               <Route path='tasks' element={<PrivateRoute component={Tasks} />}/>
               <Route path='complaints' element={<PrivateRoute component={Complaints}/>}/>
               <Route path='assessments' element={<PrivateRoute component={Assessments}/>}/>
            </Route>
         </Routes>
         <CustomSnackbar />
      </NotificationProvider>
   )
}

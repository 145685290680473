import { useMutation, useQuery, useQueryClient, UseQueryResult } from 'react-query'

import { AddComplaintPayload, AddFileType, EditComplaintPayload, FetchComplaintsResult, Result } from './types'
import { addComplaint, addFile, deleteFile, editComplaint, fetchComplaints, removeComplaint } from './services'

export function useComplaintsQuery(): UseQueryResult<FetchComplaintsResult> {
   return useQuery('complaints', fetchComplaints)
}

export function useAddComplaintMutation() {
   const queryClient = useQueryClient()

   return useMutation<Result, unknown, AddComplaintPayload, unknown>((payload) => addComplaint(payload), {
      onSuccess: (data) => {
         console.log(data)
         if (data.status === 204) {
            console.log('zareagowało na 204')
            queryClient.invalidateQueries('complaints')
         }
      },
   })
}

export function useRemoveComplaintMutation() {
   const queryClient = useQueryClient()

   return useMutation<Result, unknown, string, unknown>((complaint_id) => removeComplaint(complaint_id), {
      onSuccess: (data) => {
         if (data.status === 204) {
            queryClient.invalidateQueries('complaints')
         }
      },
   })
}

export function useEditComplaintMutation() {
   const queryClient = useQueryClient()

   return useMutation<Result, unknown, EditComplaintPayload, unknown>((payload) => editComplaint(payload), {
      onSuccess: (data) => {
         if (data.status === 204) {
            queryClient.invalidateQueries('complaints')
         }
      },
   })
}

export function useAddFileMutation() {
   const queryClient = useQueryClient()

   return useMutation<Result, unknown, AddFileType, unknown>((payload) => addFile(payload), {
      onSuccess: (data) => {
         if (data.status === 204) {
            queryClient.invalidateQueries('complaints')
         }
      },
   })
}

export function useRemoveFileMutation() {
   const queryClient = useQueryClient()

   return useMutation<Result, unknown, string, unknown>((payload) => deleteFile(payload), {
      onSuccess: (data) => {
         if (data.status === 204) {
            queryClient.invalidateQueries('complaints')
         }
      },
   })
}

import React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { Routes, Route } from 'react-router-dom'

import CustomSnackbar from 'shared/components/CustomSnackbar'
import NotificationProvider from 'shared/context/Notifications'

import UnauthorizedLayout from './components/Layout'
import Homepage from './views/Homepage'

export default function Unauthorized() {
    return (
        <NotificationProvider>
           <Routes>
              <Route path="/" element={<UnauthorizedLayout />}>
              <Route index element={<Homepage />} />
              </Route>
           </Routes>
           <CustomSnackbar />
        </NotificationProvider>
     )
}

import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from 'react-query'

import { ThemeConfig } from 'config/theme';
import Unauthorized from 'views/Unauthorized';
import PrivateRoute from 'shared/components/PrivateRoute';
import Authorized from 'views/Authorized';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const queryClient = new QueryClient({
  defaultOptions: {
     queries: {
        retryDelay: 40000,
        refetchOnWindowFocus: false,
     },
  },
})

root.render(
  <React.StrictMode>
        <ThemeConfig>
         <QueryClientProvider client={queryClient}>
            <BrowserRouter>
               <Routes>
                  <Route path="*" element={<Unauthorized />} />
                  <Route
                     path="dashboard/*"
                     element={
                        <PrivateRoute
                           component={Authorized}
                        />
                     }
                  />
               </Routes>
            </BrowserRouter>
         </QueryClientProvider>
      </ThemeConfig>
  </React.StrictMode>
);

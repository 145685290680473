import { useMutation, useQuery, useQueryClient, UseQueryResult } from 'react-query'

import {
   AddCommentPayload,
   AddTaskPayload,
   EditCommentPayload,
   EditTaskPayload,
   FetchTasksResult,
   Result,
} from './types'
import { addComment, addTask, deleteComment, editComment, editTask, fetchTasks, removeTask } from './services'

export function useTasksQuery(): UseQueryResult<FetchTasksResult> {
   return useQuery('tasks', fetchTasks)
}

export function useAddTaskMutation() {
   const queryClient = useQueryClient()

   return useMutation<Result, unknown, AddTaskPayload, unknown>((payload) => addTask(payload), {
      onSuccess: (data) => {
         if (data.status === 204) {
            queryClient.invalidateQueries('tasks')
         }
      },
   })
}

export function useRemoveTaskMutation() {
   const queryClient = useQueryClient()

   return useMutation<Result, unknown, string, unknown>((task_id) => removeTask(task_id), {
      onSuccess: (data) => {
         if (data.status === 204) {
            queryClient.invalidateQueries('tasks')
         }
      },
   })
}

export function useEditTaskMutation() {
   const queryClient = useQueryClient()

   return useMutation<Result, unknown, EditTaskPayload, unknown>((payload) => editTask(payload), {
      onSuccess: (data) => {
         if (data.status === 204) {
            queryClient.invalidateQueries('tasks')
         }
      },
   })
}

export function useAddCommentMutation() {
   const queryClient = useQueryClient()

   return useMutation<Result, unknown, AddCommentPayload, unknown>((payload) => addComment(payload), {
      onSuccess: (data) => {
         if (data.status === 204) {
            queryClient.invalidateQueries('tasks')
            queryClient.invalidateQueries('complaints')
         }
      },
   })
}

export function useEditCommentMutation() {
   const queryClient = useQueryClient()

   return useMutation<Result, unknown, EditCommentPayload, unknown>((payload) => editComment(payload), {
      onSuccess: (data) => {
         if (data.status === 204) {
            queryClient.invalidateQueries('tasks')
            queryClient.invalidateQueries('complaints')
         }
      },
   })
}

export function useDeleteCommentMutation() {
   const queryClient = useQueryClient()

   return useMutation<Result, unknown, string, unknown>((comment_id) => deleteComment(comment_id), {
      onSuccess: (data) => {
         if (data.status === 204) {
            queryClient.invalidateQueries('tasks')
            queryClient.invalidateQueries('complaints')
         }
      },
   })
}

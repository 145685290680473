import React, { useState, useEffect } from 'react'
import Box from '@mui/material/Box'
import Papa from 'papaparse'
import { CSVLink } from 'react-csv'
import Typography from '@mui/material/Typography'
import Input from '@mui/material/Input'
import ContainedButton from 'shared/components/Buttons/ContainedButton'
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'

interface CSVType {
   order: {
      ID: string
      stan: number
      prog: number
   }[]
   awParts: {
      sku: string
      stan: number
   }[]
   BL: {
      ID: string
      quantityInSetBL: Nullable<number>
      AWPartsNumber: string
      quantityInSetAWParts: Nullable<number>
      AWPartsPrice: Nullable<number>
      polcarNumber: string
      quantityInSetPolcar: Nullable<number>
   }[]
   polcar: {
      sku: string
      stan: number
      sku2: string
      cena: number
      cenaVat: number
   }[]
}

export default function Order() {
   const [csv, setCsv] = useState<CSVType>({
      order: [],
      awParts: [],
      polcar: [],
      BL: [],
   })
   const [isCreating, setIsCreating] = useState(false)
   const [createdOrder, setCreatedOrder] = useState<any[]>([])

   function handleOrderCSV(e: any) {
      if (e.target.files) {
         Papa.parse(e.target.files[0], {
            header: false,
            complete(result: any) {
               var newOrder: any[] = []
               result.data.forEach((item: any) => {
                  if (!!item[0]) {
                     newOrder.push({
                        ID: item[0],
                        stan: Number(item[1]),
                        prog: Number(item[2]),
                     })
                  }
               })

               setCsv({ ...csv, order: newOrder.splice(1, newOrder.length) })
            },
         })
      }
   }

   function handleAWPartsCSV(e: any) {
      if (e.target.files) {
         Papa.parse(e.target.files[0], {
            header: false,
            complete(result: any) {
               var newAwParts: any[] = []
               result.data.forEach((item: any) =>
                  newAwParts.push({
                     sku: item[0] || null,
                     stan: Number(item[1]),
                  })
               )

               setCsv({ ...csv, awParts: newAwParts.splice(1, newAwParts.length - 1) })
            },
         })
      }
   }

   function handleBL(e: any) {
      if (e.target.files) {
         Papa.parse(e.target.files[0], {
            header: false,
            complete(result: any) {
               var newBL: any[] = []
               result.data.forEach((item: any) =>
                  newBL.push({
                     ID: item[0],
                     quantityInSetBL: Number(item[1]) || null,
                     AWPartsNumber: item[2] || null,
                     quantityInSetAWParts: Number(item[3]) || null,
                     AWPartsPrice: item[4] || null,
                     polcarNumber: item[5] || null,
                     quantityInSetPolcar: Number(item[6]) || null,
                  })
               )
               setCsv({ ...csv, BL: newBL.splice(1, newBL.length - 1) })
            },
         })
      }
   }

   function handlePolcarCSV(e: any) {
      if (e.target.files) {
         Papa.parse(e.target.files[0], {
            header: false,
            complete(result: any) {
               var newPolcar: any[] = []
               result.data.forEach((item: any) =>
                  newPolcar.push({
                     sku: item[0] || null,
                     stan: Number(item[1]),
                     sku2: item[2] || null,
                     cena: Number(item[3]),
                     cenaVat: item[3] * 1.23,
                  })
               )

               setCsv({ ...csv, polcar: newPolcar.splice(1, newPolcar.length - 1) })
            },
         })
      }
   }

   function createOrder() {
      setIsCreating(true)
      setCreatedOrder([])
      let toOrder: any[] = []
      csv.order.forEach((item) => {
         let orderProductInBl: any
         let awPartsItem
         let polcarItemPrice
         let polcarItemQuantity
         let quantityToOrder = item.prog - item.stan
         let tempQuantityToOrderPolcar
         let tempQuantityToOrderAWParts
         orderProductInBl = csv.BL.find((baselinkerItem) => baselinkerItem.ID === item.ID)
         if (orderProductInBl) {
            if (orderProductInBl.AWPartsNumber) {
               awPartsItem = csv.awParts
               .filter((item) => item.sku)
               .find((item) => item.sku === orderProductInBl.AWPartsNumber)
               tempQuantityToOrderAWParts = Math.ceil(
                  (orderProductInBl.quantityInSetBL / orderProductInBl.quantityInSetAWParts) * quantityToOrder
               )
            }
            if (orderProductInBl.polcarNumber) {
               polcarItemPrice = csv.polcar
               .filter((item) => item.sku2)
               .find((item) => item.sku2 === orderProductInBl.polcarNumber)
               polcarItemQuantity = csv.polcar
               .filter((item) => item.sku)
               .find((item) => item.sku === orderProductInBl.polcarNumber)
               
               tempQuantityToOrderPolcar = Math.ceil(
                  (orderProductInBl.quantityInSetBL / orderProductInBl.quantityInSetPolcar) * quantityToOrder
               )
            }
            if (!orderProductInBl.polcarNumber && !orderProductInBl.AWPartsNumber) {
               toOrder.push({ ID: item.ID, toOrder: quantityToOrder, from: 'BRAK NUMERU CZĘŚCI W POLCAR I AWPARTS' })
            }
            else if (!orderProductInBl.AWPartsNumber && orderProductInBl.polcarNumber) {
               if (polcarItemQuantity?.stan) {
                  if (tempQuantityToOrderPolcar) {
                     if (tempQuantityToOrderPolcar <= polcarItemQuantity.stan) {
                        toOrder.push({ ID: item.ID, toOrder: tempQuantityToOrderPolcar, from: 'POLCAR', wholesaleNumber: orderProductInBl.polcarNumber })
                     } else {
                        toOrder.push({
                           ID: item.ID,
                           toOrder: quantityToOrder,
                           from: 'BRAK (BRAK NUMERU W AWPARTS I ZA MAŁY STAN W POLCAR)',
                        })
                     }
                  } else {
                     toOrder.push({
                        ID: item.ID,
                        toOrder: quantityToOrder,
                        from: 'BRAK (BRAK INFORMACJI O ILOŚCI W PACZCE POLCAR)',
                     })
                  }
               } else {
                  toOrder.push({
                     ID: item.ID,
                     toOrder: quantityToOrder,
                     from: 'BRAK (BRAK INFORMACJI O STANIE POLCAR LUB STAN ZEROWY )',
                  })
               }
            }
            else if (orderProductInBl.AWPartsNumber && !orderProductInBl.polcarNumber) {
               if (awPartsItem?.stan) {
                  if (tempQuantityToOrderAWParts) {
                     if (tempQuantityToOrderAWParts <= awPartsItem.stan) {
                        toOrder.push({ ID: item.ID, toOrder: tempQuantityToOrderAWParts, from: 'AWPARTS', wholesaleNumber: orderProductInBl.AWPartsNumber })
                     } else {
                        toOrder.push({
                           ID: item.ID,
                           toOrder: quantityToOrder,
                           from: 'BRAK (BRAK NUMERU W POLCAR I ZA MAŁY STAN W AWPARTS)',
                        })
                     }
                  } else {
                     toOrder.push({
                        ID: item.ID,
                        toOrder: quantityToOrder,
                        from: 'BRAK (BRAK INFORMACJI O ILOŚCI W PACZCE AWPARTS)',
                     })
                  }
               } else {
                  toOrder.push({
                     ID: item.ID,
                     toOrder: quantityToOrder,
                     from: 'BRAK (BRAK INFORMACJI O STANIE AWPARTS LUB STAN ZEROWY)',
                  })
               }
            }
            else if (orderProductInBl.AWPartsNumber && orderProductInBl.polcarNumber) {
               // console.log(orderProductInBl)
               // console.log('tempToOrderPolcar', tempQuantityToOrderPolcar)
               // console.log('tempToOrderAW', tempQuantityToOrderAWParts)
               // console.log('stany aw', awPartsItem?.stan)
               // console.log('stan polcar', polcarItemQuantity?.stan)
               // console.log('cena polcar', polcarItemPrice?.cenaVat)
               // console.log('cena aw', parseFloat(orderProductInBl.AWPartsPrice.replaceAll(',','.')))
               // console.log("-------------------------NEXT ITEM----------------")
               if (typeof(polcarItemQuantity?.stan) !== 'undefined' && !awPartsItem?.stan) {
                  if (polcarItemQuantity?.stan) {
                     if (tempQuantityToOrderPolcar) {
                        if (tempQuantityToOrderPolcar <= polcarItemQuantity.stan) {
                           toOrder.push({ ID: item.ID, toOrder: tempQuantityToOrderPolcar, from: 'POLCAR', wholesaleNumer: orderProductInBl.polcarNumber })
                        } else {
                           toOrder.push({
                              ID: item.ID,
                              toOrder: quantityToOrder,
                              from: 'BRAK (BRAK NUMERU W AWPARTS I ZA MAŁY STAN W POLCAR)',
                           })
                        }
                     } else {
                        toOrder.push({
                           ID: item.ID,
                           toOrder: quantityToOrder,
                           from: 'BRAK (BRAK INFORMACJI O ILOŚCI W PACZCE POLCAR)',
                        })
                     }
                  } else {
                     toOrder.push({
                        ID: item.ID,
                        toOrder: quantityToOrder,
                        from: 'BRAK (BRAK INFORMACJI O STANIE POLCAR LUB STAN ZEROWY )',
                     })
                  }
               }
               else if (!polcarItemQuantity?.stan && typeof(awPartsItem?.stan) !== 'undefined') {
                  if (awPartsItem?.stan) {
                     if (tempQuantityToOrderAWParts) {
                        if (tempQuantityToOrderAWParts <= awPartsItem.stan) {
                           toOrder.push({ ID: item.ID, toOrder: tempQuantityToOrderAWParts, from: 'AWPARTS', wholesaleNumber: orderProductInBl.AWPartsNumber})
                        } else {
                           toOrder.push({
                              ID: item.ID,
                              toOrder: quantityToOrder,
                              from: 'BRAK (BRAK NUMERU W POLCAR I ZA MAŁY STAN W AWPARTS)',
                           })
                        }
                     } else {
                        toOrder.push({
                           ID: item.ID,
                           toOrder: quantityToOrder,
                           from: 'BRAK (BRAK INFORMACJI O ILOŚCI W PACZCE AWPARTS)',
                        })
                     }
                  } else {
                     toOrder.push({
                        ID: item.ID,
                        toOrder: quantityToOrder,
                        from: 'BRAK (BRAK INFORMACJI O STANIE AWPARTS LUB STAN ZEROWY)',
                     })
                  }
               }
               else if (typeof(polcarItemQuantity?.stan) !== 'undefined' && typeof(awPartsItem?.stan) !== 'undefined') {
                  if (tempQuantityToOrderPolcar && tempQuantityToOrderAWParts) {
                     if (
                        tempQuantityToOrderPolcar > polcarItemQuantity.stan &&
                        tempQuantityToOrderAWParts <= awPartsItem.stan
                     ) {
                        toOrder.push({ ID: item.ID, toOrder: tempQuantityToOrderAWParts, from: 'AWParts', wholesaleNumber: orderProductInBl.AWPartsNumber })
                     }
                     else if (
                        tempQuantityToOrderPolcar <= polcarItemQuantity.stan &&
                        tempQuantityToOrderAWParts > awPartsItem.stan
                     ) {
                        toOrder.push({ ID: item.ID, toOrder: tempQuantityToOrderPolcar, from: 'POLCAR', wholesaleNumber: orderProductInBl.polcarNumber })
                     }
                     else if (
                        tempQuantityToOrderPolcar <= polcarItemQuantity.stan &&
                        tempQuantityToOrderAWParts <= awPartsItem.stan
                     ) {
                        if (
                           (polcarItemPrice?.cenaVat || 0) * tempQuantityToOrderPolcar >
                           parseFloat(orderProductInBl.AWPartsPrice.replaceAll(',','.')) * tempQuantityToOrderAWParts
                        ) {
                           toOrder.push({ ID: item.ID, toOrder: tempQuantityToOrderAWParts, from: 'AWPARTS', wholesaleNumber: orderProductInBl.AWPartsNumber })
                        } else {
                           toOrder.push({ ID: item.ID, toOrder: tempQuantityToOrderPolcar, from: 'POLCAR', wholesaleNumber: orderProductInBl.polcarNumber })
                        }
                     }
                  }
               }
            }
         } else {
            toOrder.push({ ID: item.ID, toOrder: quantityToOrder, from: 'BRAK W KARTOTECE' })
         }
      })
      setIsCreating(false)
      setCreatedOrder(toOrder)
   }

   function generateCSV() {
      const CSVData = []
      if (createdOrder) {
         const title: string[] = ['ID produktu', 'Ilośc do zamówienia', 'Skąd zamówić', 'Numer w hurtowni']
         CSVData.push(title)
         createdOrder.map((item) => {
            return CSVData.push([item.ID, item.toOrder, item.from, item.wholesaleNumber || ''])
         })
      }

      const date = new Date()

      return (
         <CSVLink filename={`Zamówienie stworzone dnia: ${date.toLocaleString('pl-PL')}`} data={CSVData}>
            <ContainedButton variant="contained">Wyeksportuj do CSV</ContainedButton>
         </CSVLink>
      )
   }

   return (
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3, alignItems: 'center', pt: 3 }}>
         <Typography variant="h6">Wygeneruj zamówienia</Typography>
         <Box>
            <Typography variant="body2"> Plik csv z zamówieniem </Typography>
            <Input type="file" onChange={(e) => handleOrderCSV(e)} />
         </Box>
         <Box>
            <Typography variant="body2"> Plik csv ze stanami AWParts </Typography>
            <Input type="file" onChange={(e) => handleAWPartsCSV(e)} />
         </Box>
         <Box>
            <Typography variant="body2"> Plik csv ze stanami Polcar</Typography>
            <Input type="file" onChange={(e) => handlePolcarCSV(e)} />
         </Box>
         <Box>
            <Typography variant="body2"> Plik csv z kartoteką BL</Typography>
            <Input type="file" onChange={(e) => handleBL(e)} />
         </Box>
         <Box sx={{textAlign:'center', fontWeight:'bold'}}>
            ----------------WAŻNE!!----------------<br/>
            Pliki muszą mieć zachowany standard, w przeciwnym wypadku kod zwróci zakłamane wyniki.<br/>
            Standardy plików csv: <br/>
            1. CSV z zamówieniem musi zawierać 3 kolumny w odpowiedniej kolejności: ID, stan, prog<br/>
            2. CSV ze stanami AWParts musi zawierać tylko 2 kolumny w odpowiedniej kolejności: SKU, stan<br/>
            3. CSV ze stanami Polcar musi zawierać tylko 4 kolumn w odpowiedniej kolejności: sku, stan, sku, cena (pisana jako liczba z kropką np. 3.54)<br/>
            4. CSV z kartoteka baselinkera musi zawierać 7 kolumn w odpowiedniej kolejności: ID, ilość w paczce baselinker, numer AWParts, ilość w paczce AWParts, cena AWParts, numer polcar, ilość w paczce polcar<br/>
            Jeśli po stworzeniu jednego zamówienia chcesz dodać drugie w oparciu o inny plik najlepiej dla pewności odświeżyć stronę.
         </Box>
         <ContainedButton disabled={isCreating} variant="contained" onClick={createOrder}>
            Stwórz zamówienie
         </ContainedButton>
         {createdOrder.length > 0 && (
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3, marginTop: 5 }}>
               <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Typography variant="body1">Twoje zamówienie:</Typography>
                  {generateCSV()}
               </Box>
               <Table>
                  <TableHead>
                     <TableRow>
                        <TableCell>ID produktu</TableCell>
                        <TableCell>Ilość sztuk do zamówienia</TableCell>
                        <TableCell>Skąd</TableCell>
                        <TableCell>Numer w hurtowni</TableCell>
                     </TableRow>
                  </TableHead>
                  <TableBody>
                     {createdOrder.map((item) => (
                        <TableRow key={item.ID}>
                           <TableCell>{item.ID}</TableCell>
                           <TableCell>{item.toOrder}</TableCell>
                           <TableCell>{item.from}</TableCell>
                           <TableCell>{item.wholesaleNumber || ''}</TableCell>
                        </TableRow>
                     ))}
                  </TableBody>
               </Table>
            </Box>
         )}
      </Box>
   )
}

import Dialog from '@mui/material/Dialog'
import Typography from '@mui/material/Typography'

import { ModalActionType, ModalInfo } from '../../types'
import AddSectionModal from '../AddSectionModal'
import RemoveSectionModal from '../RemoveSectionModal'
import EditSectionModal from '../EditSectionModal'
import CommentsModal from '../CommentsModal'
import RemoveComplaintModal from '../RemoveComplaintModal'
import MoveComplaintModal from '../MoveComplaintModal'
import EditComplaintModal from '../EditComplaintModal'
import AddComplaintModal from '../AddComplaintModal'
import AddFileModal from '../AddFileModal'
import RemoveFileModal from '../RemoveFileModal'

interface Props {
   actionType: ModalActionType
   handleClose: () => void
   modalInfo?: ModalInfo
}

export default function Modal({ actionType, handleClose, modalInfo }: Props) {
   function renderTitle() {
      switch (actionType) {
         case 'ADD_SECTION':
            return 'Dodaj tabele'
         case 'ADD_COMPLAINT':
            return 'Dodaj reklamację'
         case 'EDIT_SECTION':
            return 'Edytuj tabele'
         case 'EDIT_COMPLAINT':
            return 'Edytuj reklamację'
         case 'REMOVE_SECTION':
            return 'Usuń tabele'
         case 'REMOVE_COMPLAINT':
            return 'Usuń reklamację'
         case 'MOVE_COMPLAINT':
            return 'Przenieś reklamację'
         case 'COMMENTS':
            return 'Komentarze'
         case 'ADD_FILE':
            return 'Załącz plik do reklamacji'
         case 'REMOVE_FILE':
            return 'Usuń plik'
         default:
            return null
      }
   }

   function renderContent() {
      switch (actionType) {
         case 'ADD_SECTION':
            return <AddSectionModal handleClose={handleClose} />
         case 'ADD_COMPLAINT':
            return <AddComplaintModal handleClose={handleClose} sectionId={modalInfo?.section_id || ''} />
         case 'EDIT_SECTION':
            return (
               <EditSectionModal
                  sectionName={modalInfo?.section_name || ''}
                  sectionColor={modalInfo?.section_color || ''}
                  handleClose={handleClose}
                  sectionId={modalInfo?.section_id || ''}
               />
            )
         case 'EDIT_COMPLAINT':
            return (
               <EditComplaintModal
                  content={modalInfo?.content || ''}
                  complaint_id={modalInfo?.complaint_id || ''}
                  handleClose={handleClose}
               />
            )
         case 'REMOVE_SECTION':
            return <RemoveSectionModal section_id={modalInfo?.section_id || ''} handleClose={handleClose} />
         case 'REMOVE_COMPLAINT':
            return <RemoveComplaintModal handleClose={handleClose} complaint_id={modalInfo?.complaint_id || ''} />
         case 'MOVE_COMPLAINT':
            return (
               <MoveComplaintModal
                  handleClose={handleClose}
                  complaint_id={modalInfo?.complaint_id || ''}
                  section_id={modalInfo?.section_id || ''}
               />
            )
         case 'COMMENTS':
            return <CommentsModal handleClose={handleClose} complaint_id={modalInfo?.complaint_id || ''} />
         case 'ADD_FILE':
            return <AddFileModal handleClose={handleClose} complaint_id={modalInfo?.complaint_id || ''} />
         case 'REMOVE_FILE':
            return <RemoveFileModal handleClose={handleClose} file_id={modalInfo?.file_id || ''} />
         default:
            return null
      }
   }

   return (
      <Dialog onClose={handleClose} open={actionType !== undefined}>
         <Typography color="primary" sx={{ margin: '0 auto', mt: 2 }} variant="h5">
            {renderTitle()}
         </Typography>
         {renderContent()}
      </Dialog>
   )
}

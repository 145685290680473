import Box from '@mui/material/Box'
import { Form, FormikProvider, useFormik } from 'formik'

import ContainedButton from 'shared/components/Buttons/ContainedButton'
import OutlinedButton from 'shared/components/Buttons/OutlinedButton'
import { useNotificationContext } from 'shared/context/Notifications'
import { AddTaskPayload } from 'shared/store/useTasks/types'
import { useAddTaskMutation } from 'shared/store/useTasks'
import InputField from 'shared/components/InputField'


import { addTaskSchema } from '../../schema'

interface Props{
    handleClose: () => void
    sectionId: string
}

export default function AddTaskModal({sectionId, handleClose} : Props) {
    const {addNotification} = useNotificationContext()
    const {mutate} = useAddTaskMutation()

    const formik = useFormik({
        initialValues: {
            name: '',
            color: '',
        },
        validationSchema: addTaskSchema,
        onSubmit: handleSubmit
    })
    const {values} = formik

    function handleSubmit() {
        const payload: AddTaskPayload = {
            name: values.name, 
            section_id: sectionId,
        }
        mutate({...payload},{
         onSuccess: () =>{
            addNotification('Udało się stworzyć zadanie', 'success')
            handleClose()
         },
         onError: () => addNotification('Nie udało się stworzyć zadania', 'error')
        })
    }

   return (
      <Box>
         <FormikProvider value={formik}>
            <Form>
               <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2, px: 5, py: 2 }}>
                  <InputField label="Treść" variant="standard" name="name" required />
                  <Box sx={{ display: 'flex', gap: 2 }}>
                     <OutlinedButton onClick={() => handleClose()}>Zamknij</OutlinedButton>
                     <ContainedButton variant='contained' type="submit">Zapisz</ContainedButton>
                  </Box>
               </Box>
            </Form>
         </FormikProvider>
      </Box>
   )
}

import { useMutation, useQueryClient } from 'react-query'
import JwtDecode from 'jwt-decode'
import { useNavigate } from 'react-router-dom'
import { authorizeUser, revokeToken } from './services'
import { AuthorizationResult, JWTTokenData, LoginPayload, StateType } from './types'

const INITIAL_STATE: StateType = { loginStatus: 'initial', id: '', admin: false }

function decodeUserToken(token: string) {
   const user = JwtDecode<JWTTokenData>(token)
   const { sub, admin } = user

   return { sub, admin }
}

async function loginIn(payload: LoginPayload): Promise<StateType> {
   const userData = await authorizeUser(payload)

   if (!userData.access_token) {
      return INITIAL_STATE
   }
   const token = userData.access_token

   const { sub, admin } = decodeUserToken(token)

   localStorage.setItem('accessToken', token)
   if (userData.refresh_token) {
      localStorage.setItem('refreshToken', userData.refresh_token)
   }

   return {
      loginStatus: 'logged',
      id: sub,
      admin,
   }
}

export async function loginOut(destination: string) {
   const refreshToken = localStorage.getItem('refreshToken') || ''
   await revokeToken(refreshToken)
   localStorage.removeItem('chooseLocationModal')
   localStorage.removeItem('acessToken')
   localStorage.removeItem('refreshToken')
   return destination
}

export function getInitialState(): StateType {
   const token = localStorage.getItem('accessToken')
   const refreshToken = localStorage.getItem('refreshToken')
   if (token && refreshToken) {
      const { sub, admin } = decodeUserToken(token)

      return {
         loginStatus: 'logged',
         id: sub,
         admin,
      }
   }

   return INITIAL_STATE
}

export default function useLogin() {
   const queryClient = useQueryClient()
   const navigate = useNavigate()

   const user = queryClient.getQueryData<StateType>('user') || getInitialState()

   const {
      mutate: login,
      error,
      isError,
      isLoading,
   } = useMutation<StateType, { message: PropType<AuthorizationResult, 'error'> }, LoginPayload>(
      (loginData) => loginIn(loginData),
      {
         onSuccess: (data) => {
            queryClient.setQueryData<StateType>('user', {
               ...user,
               ...data,
            })
            queryClient.invalidateQueries('user')
         },
         // onError: (err) => {
         //    queryClient.setQueryData<StateType>('user', {
         //       ...user,
         //    })
         // },
      }
   )

   const { mutate: logout } = useMutation<string, unknown, string>((destination) => loginOut(destination), {
      onSuccess: (destination) => {
         window.localStorage.removeItem('chooseLocationModal')
         queryClient.removeQueries()
         // queryClient.setQueryData<StateType>('user', {
         //    ...INITIAL_STATE,
         // })
         navigate(destination)
      },
   })
   return { user, login, logout, error, isError, isLoading }
}

import DeleteIcon from '@mui/icons-material/Delete'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import TextField from '@mui/material/TextField'
import Box from '@mui/material/Box'
import { useEffect, useState } from 'react'

import {
   useAddCommentMutation,
   useDeleteCommentMutation,
   useEditCommentMutation,
   useTasksQuery,
} from 'shared/store/useTasks'
import ContainedButton from 'shared/components/Buttons/ContainedButton'
import { useNotificationContext } from 'shared/context/Notifications'
import { CommentType } from 'shared/store/useTasks/types'
import useLogin from 'shared/store/useLogin'

interface Props {
   handleClose: () => void
   task_id: string
}

export default function CommentsModal({ task_id, handleClose }: Props) {
   const { addNotification } = useNotificationContext()
   const [comment, setComment] = useState('')
   const { data } = useTasksQuery()
   const { user } = useLogin()
   const [comments, setComments] = useState<CommentType[]>()
   const { mutate, isLoading } = useAddCommentMutation()
   const { mutate: editMutate, isLoading: isEditLoading } = useEditCommentMutation()
   const { mutate: deleteMutate, isLoading: deleteLoading } = useDeleteCommentMutation()

   function handleAddComment() {
      mutate(
         {
            section_type: 'TASK',
            target_id: task_id,
            content: comment,
         },
         {
            onSuccess: () => addNotification('Twój komentarz został dodany', 'success'),
            onError: () => addNotification('Nie udało się dodać Twojego komentarza', 'error'),
         }
      )
   }

   function handleEdit(newComment: string, comment: string, comment_id: string) {
      if (newComment === comment) {
         addNotification('W Twoim komentarzu nic się nie zmieniło', 'error')
      } else {
         editMutate(
            {
               comment_id,
               content: newComment,
            },
            {
               onSuccess: () => addNotification('Twój komentarz został edytowany', 'success'),
               onError: () => addNotification('Nie udało się edytować Twojego komentarza', 'error'),
            }
         )
      }
   }

   function handleDelete(comment_id: string) {
      deleteMutate(comment_id, {
         onSuccess: () => addNotification('Twój komentarz został usunięty', 'success'),
         onError: () => addNotification('Nie udało się usunąć Twojego komentarza', 'error'),
      })
   }

   useEffect(() => {
      data?.sections.map((item) =>
         item.tasks.map((task) => {
            if (task.task_id === task_id) {
               setComments(task.comments)
            }
         })
      )
   }, [data])

   return (
      <Box>
         <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2, mx: 3, my: 2 }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, justifyContent: 'center' }}>
               {comments?.map((item) => (
                  <Box key={item.comment_id} sx={{ display: 'flex', gap: 1 }}>
                     <TextField
                        disabled={isEditLoading || (!user.admin && user.id !== item.user_id)}
                        onBlur={(e) => handleEdit(e.target.value as string, item.content, item.comment_id)}
                        multiline
                        sx={{ minWidth: 350 }}
                        defaultValue={item.content}
                        label={`${item.username} (${item.updated_date.split('T')[0]} ${
                           item.updated_date.split('T')[1].split('.')[0]
                        })`}
                     />
                     <IconButton disabled={deleteLoading} onClick={() => handleDelete(item.comment_id)}>
                        <DeleteIcon sx={{ '&:hover': { color: 'red' } }} />
                     </IconButton>
                  </Box>
               ))}
               <Box sx={{ display: 'flex', gap: 1 }}>
                  <TextField
                     InputProps={{
                        rows: 4,
                        multiline: true,
                        inputComponent: 'textarea',
                     }}
                     value={comment}
                     onChange={(e) => setComment(e.target.value)}
                     disabled={isLoading}
                     label="Nowy komentarz"
                     variant="outlined"
                  />
                  <ContainedButton disabled={isLoading} variant="contained" onClick={handleAddComment}>
                     Dodaj
                  </ContainedButton>
               </Box>
            </Box>
            <Box sx={{ display: 'flex', gap: 2 }}>
               <ContainedButton variant="contained" onClick={handleClose}>
                  Zamknij
               </ContainedButton>
            </Box>
         </Box>
      </Box>
   )
}

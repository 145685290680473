import React from 'react'
import { Link } from 'react-router-dom'
import IconButton from '@mui/material/IconButton'
import Box from '@mui/material/Box'
import LogoutIcon from '@mui/icons-material/Logout'

import { RootStyle } from './styles'
import useLogin from 'shared/store/useLogin'

export default function Header() {
   const { logout } = useLogin()

   return (
      <RootStyle>
         <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', mx: 5 }}>
            <Link
               to="/dashboard"
               style={{
                  textDecoration: 'none',
                  color: 'white',
                  fontFamily: 'didot',
                  marginLeft: 35,
                  fontSize: 32,
                  fontWeight: 'bold',
               }}
            >
               MOTOLI
            </Link>
            <IconButton onClick={() => logout('/')}>
               <LogoutIcon color="error" />
            </IconButton>
         </Box>
      </RootStyle>
   )
}

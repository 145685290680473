import * as Yup from 'yup'

Yup.setLocale({
   mixed: {
      required: 'To pole jest wymagane',
   },

   string: {
      email: 'To pole nie jest emailem',
      max: 'To pole jest za długie',
      min: 'To pole jest za krótkie',
   },
})
export { Yup }

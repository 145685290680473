import { useMutation, useQuery, useQueryClient, UseQueryResult } from 'react-query'

import { EditUserPayload, FetchUsersResult, Result } from './types'
import { editUser, fetchUsers } from './services'



export function useUsersQuery(userId: string): UseQueryResult<FetchUsersResult> {
   return useQuery(['users', (userId)], () => fetchUsers(userId), { staleTime: 10 * (60 * 1000) })
}

export function useEditUserMutation() {
   const queryClient = useQueryClient()

   return useMutation<Result, unknown, EditUserPayload, unknown>((payload) => editUser(payload), {
      onSuccess: (data) => {
         if (data.status === 204) {
            queryClient.invalidateQueries('users')
         }
      },
   })
}

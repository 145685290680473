import React from 'react'
import Dialog from '@mui/material/Dialog'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { Form, FormikProvider, useFormik } from 'formik'

import InputField from 'shared/components/InputField'
import ContainedButton from 'shared/components/Buttons/ContainedButton'
import OutlinedButton from 'shared/components/Buttons/OutlinedButton'
import { useEditUserMutation } from 'shared/store/useUsers'
import { EditUserPayload } from 'shared/store/useUsers/types'
import { useNotificationContext } from 'shared/context/Notifications'

import { EditUserType } from './types'
import schema from './schema'

interface Props {
   userId: string
   handleClose: () => void
}

export default function EditUserModal({ userId, handleClose }: Props) {
    const {mutate, isLoading} = useEditUserMutation()
    const {addNotification} = useNotificationContext()

   const formik = useFormik<EditUserType>({
      initialValues: {
         login: '',
         password: '',
      },
      validationSchema: schema,
      onSubmit: handleSubmit,
   })
   
   const {values} = formik

   function handleSubmit() {
    const payload: EditUserPayload = {
        login: values.login,
        password: btoa(values.password),
        userId
    }

    mutate(payload, {
        onSuccess: () => addNotification('Udało się zapisać informacje', 'success'),
        onError: () => addNotification('Nie udało się zapisać. Spróbuj ponownie później.','error')
    })
   }

   return (
      <Dialog onClose={handleClose} open={userId !== ''}>
         <Typography color='primary' sx={{ margin: '0 auto', mt: 2 }} variant="h5">
            Edycja użytkownika
         </Typography>
         <FormikProvider value={formik}>
            <Form>
               <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2, px: 5, py: 2 }}>
                  <InputField label="Login" variant="standard" name="login" required />
                  <InputField label="Hasło" type="password" variant="standard" name="password" required />
                  <Box sx={{ display: 'flex', gap: 2 }}>
                     <OutlinedButton onClick={() => handleClose()}>Zamknij</OutlinedButton>
                     <ContainedButton disabled={isLoading} variant='contained' type="submit">Zapisz</ContainedButton>
                  </Box>
               </Box>
            </Form>
         </FormikProvider>
      </Dialog>
   )
}

import Box from '@mui/material/Box'
import { Form, FormikProvider, useFormik } from 'formik'

import { EditComplaintPayload } from 'shared/store/useComplaints/types'
import ContainedButton from 'shared/components/Buttons/ContainedButton'
import OutlinedButton from 'shared/components/Buttons/OutlinedButton'
import { useNotificationContext } from 'shared/context/Notifications'
import { useEditComplaintMutation } from 'shared/store/useComplaints'
import InputField from 'shared/components/InputField'

import { addComplaintSchema } from '../../schema'

interface Props {
   handleClose: () => void
   complaint_id: string
   content: string
}

export default function EditTaskModal({ complaint_id, content, handleClose }: Props) {
   const { addNotification } = useNotificationContext()
   const { mutate } = useEditComplaintMutation()

   const formik = useFormik({
      initialValues: {
         name: '',
      },
      validationSchema: addComplaintSchema,
      onSubmit: handleSubmit,
   })
   const { values } = formik

   function handleSubmit() {
      const payload: EditComplaintPayload = {
         name: values.name,
         complaint_id,
      }
      mutate(
         { ...payload },
         {
            onSuccess: () => {
               addNotification('Udało się zapisać zmiany', 'success')
               handleClose()
            },
            onError: () => addNotification('Nie udało się zapisać zmian', 'error'),
         }
      )
   }

   return (
      <Box>
         <FormikProvider value={formik}>
            <Form>
               <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2, px: 5, py: 2 }}>
                  <InputField defaultValue={content} label="Treść" variant="standard" name="name" required />
                  <Box sx={{ display: 'flex', gap: 2 }}>
                     <OutlinedButton onClick={() => handleClose()}>Zamknij</OutlinedButton>
                     <ContainedButton variant="contained" type="submit">
                        Zapisz
                     </ContainedButton>
                  </Box>
               </Box>
            </Form>
         </FormikProvider>
      </Box>
   )
}

import Dialog from '@mui/material/Dialog'
import Typography from '@mui/material/Typography'

import { ModalActionType, ModalInfo } from '../../types'
import RemoveSectionModal from '../RemoveSectionModal'
import EditSectionModal from '../EditSectionModal'
import RemoveTaskModal from '../RemoveTaskModal'
import AddSectionModal from '../AddSectionModal'
import EditTaskModal from '../EditTaskModal'
import MoveTaskModal from '../MoveTaskModal'
import CommentsModal from '../CommentsModal'
import AddTaskModal from '../AddTaskModal'

interface Props {
   actionType: ModalActionType
   handleClose: () => void
   modalInfo?: ModalInfo
}

export default function Modal({ actionType, handleClose, modalInfo }: Props) {
   function renderTitle() {
      switch (actionType) {
         case 'ADD_SECTION':
            return 'Dodaj tabele'
         case 'ADD_TASK':
            return 'Dodaj zadanie'
         case 'EDIT_SECTION':
            return 'Edytuj tabele'
         case 'EDIT_TASK':
            return 'Edytuj zadanie'
         case 'REMOVE_SECTION':
            return 'Usuń tabele'
         case 'REMOVE_TASK':
            return 'Usuń zadanie'
         case 'MOVE_TASK':
            return 'Przenieś zadanie'
         case 'COMMENTS':
            return 'Komentarze'
         default:
            return null
      }
   }

   function renderContent() {
      switch (actionType) {
         case 'ADD_SECTION':
            return <AddSectionModal handleClose={handleClose} />
         case 'ADD_TASK':
            return <AddTaskModal handleClose={handleClose} sectionId={modalInfo?.section_id || ''} />
         case 'EDIT_SECTION':
            return <EditSectionModal handleClose={handleClose} sectionId={modalInfo?.section_id || ''} />
         case 'EDIT_TASK':
            return <EditTaskModal task_id={modalInfo?.task_id || ''} handleClose={handleClose} />
         case 'REMOVE_SECTION':
            return <RemoveSectionModal section_id={modalInfo?.section_id || ''} handleClose={handleClose} />
         case 'REMOVE_TASK':
            return <RemoveTaskModal handleClose={handleClose} task_id={modalInfo?.task_id || ''} />
         case 'MOVE_TASK':
            return (
               <MoveTaskModal
                  handleClose={handleClose}
                  task_id={modalInfo?.task_id || ''}
                  section_id={modalInfo?.section_id || ''}
               />
            )
         case 'COMMENTS':
            return <CommentsModal handleClose={handleClose} task_id={modalInfo?.task_id || ''} />
         default:
            return null
      }
   }

   return (
      <Dialog onClose={handleClose} open={actionType !== undefined}>
         <Typography color="primary" sx={{ margin: '0 auto', mt: 2 }} variant="h5">
            {renderTitle()}
         </Typography>
         {renderContent()}
      </Dialog>
   )
}

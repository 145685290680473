import React from 'react'
import { Navigate } from 'react-router-dom'

import useLogin from 'shared/store/useLogin'

interface Props {
   component: React.ComponentType
}

function PrivateRoute({ component: RouteComponent }: Props) {
   const isAuthenticated = window.localStorage.getItem('accessToken')

   if (isAuthenticated) {
      return <RouteComponent />
   }

   return <Navigate to="/" />
}

export default PrivateRoute

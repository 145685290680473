import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import ContainedButton from 'shared/components/Buttons/ContainedButton'
import OutlinedButton from 'shared/components/Buttons/OutlinedButton'

import { useNotificationContext } from 'shared/context/Notifications'
import { useRemoveComplaintMutation } from 'shared/store/useComplaints'

interface Props {
   handleClose: () => void
   complaint_id: string
}

export default function RemoveComplaintModal({ complaint_id, handleClose }: Props) {
   const { addNotification } = useNotificationContext()
   const { mutate } = useRemoveComplaintMutation()

   function handleSubmit() {
      mutate(complaint_id, {
         onSuccess: () => {
            addNotification('Udało się usunąć zadanie', 'success')
            handleClose()
         },
         onError: () => addNotification('Nie udało się usunąć zadania', 'error'),
      })
   }

   return (
      <Box>
         <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2, mx:3, my:2 }}>
         <Typography variant="body1"> Czy na pewno chcesz usunąć tą reklamację?</Typography>
            <Box sx={{ display: 'flex', gap: 2 }}>
               <OutlinedButton onClick={() => handleClose()}>Zamknij</OutlinedButton>
               <ContainedButton variant="contained" onClick={handleSubmit}>
                  Zapisz
               </ContainedButton>
            </Box>
         </Box>
      </Box>
   )
}

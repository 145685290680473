import {Yup} from 'shared/lib'

export const addSectionSchema = Yup.object().shape({
    name: Yup.string().required(),
    color: Yup.string().required()
})

export const addComplaintSchema = Yup.object().shape({
    name: Yup.string().required(),
})

import { useMutation, useQuery, useQueryClient, UseQueryResult } from 'react-query'

import {
   FetchSectionsResult,
   SectionType,
   Result,
   AddSectionPayload,
   RemoveSectionPayload,
   EditSectionPayload,
} from './types'
import { addSection, editSection, fetchSections, removeSection } from './services'

export function useSectionsQuery(type: SectionType): UseQueryResult<FetchSectionsResult> {
   return useQuery(['sections', type], () => fetchSections(type))
}

export function useAddSectionMutation() {
   const queryClient = useQueryClient()

   return useMutation<Result, unknown, AddSectionPayload, unknown>((payload) => addSection(payload), {
      onSuccess: (data) => {
         if (data.status === 204) {
            queryClient.invalidateQueries('sections')
            queryClient.invalidateQueries('complaints')
            queryClient.invalidateQueries('tasks')
         }
      },
   })
}

export function useEditSectionMutation() {
   const queryClient = useQueryClient()

   return useMutation<Result, unknown, EditSectionPayload, unknown>((payload) => editSection(payload), {
      onSuccess: (data) => {
         if (data.status === 204) {
            queryClient.invalidateQueries('sections')
            queryClient.invalidateQueries('complaints')
            queryClient.invalidateQueries('tasks')
         }
      },
   })
}

export function useRemoveSectionMutation() {
   const queryClient = useQueryClient()

   return useMutation<Result, unknown, RemoveSectionPayload, unknown>((payload) => removeSection(payload), {
      onSuccess: (data) => {
         if (data.status === 204) {
            queryClient.invalidateQueries('sections')
            queryClient.invalidateQueries('complaints')
            queryClient.invalidateQueries('tasks')
         }
      },
   })
}

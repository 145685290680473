import { httpClient } from 'shared/lib/httpClient'

import { AddComplaintPayload, AddFileType, EditComplaintPayload, FetchComplaintsResult } from './types'

export function fetchComplaints(): Promise<FetchComplaintsResult> {
   return httpClient
      .get('complaints')
      .then((res) => {
         if (res.data) {
            return res.data
         }
         throw new Error('noData')
      })
      .catch((err) => {
         throw new Error(err.response.message)
      })
}

export function addComplaint(payload: AddComplaintPayload) {
   return httpClient
      .post('complaints', {
         ...payload,
      })
      .then((res) => ({ status: res.status }))
      .catch((err) => {
         throw new Error(err)
      })
}

export function removeComplaint(complaint_id: string) {
   return httpClient
      .delete(`complaints/${complaint_id}`)
      .then((res) => ({ status: res.status }))
      .catch((err) => {
         throw new Error(err)
      })
}

export function editComplaint(payload: EditComplaintPayload) {
   const { complaint_id: _, ...rest } = payload
   return httpClient
      .patch(`complaints/${payload.complaint_id}`, {
         ...rest,
      })
      .then((res) => ({ status: res.status }))
      .catch((err) => {
         throw new Error(err)
      })
}

export function addFile(payload: AddFileType) {
   return httpClient
      .post(`files/COMPLAINT/${payload.target_uuid}`, payload.file)
      .then((res) => ({ status: res.status }))
      .catch((err) => {
         throw new Error(err)
      })
}

export function deleteFile(fileUuid: string) {
   return httpClient
      .delete(`files/${fileUuid}`)
      .then((res) => ({ status: res.status }))
      .catch((err) => {
         throw new Error(err)
      })
}

import React from 'react'
import { Outlet } from 'react-router-dom'

import Footer from '../Footer'

import Header from '../Header'

function UnauthorizedLayout() {
   return (
      <>
         <Header />
         <main style={{minHeight:'calc(100vh - 114px)'}}>
            <Outlet />
         </main>
         <Footer />
      </>
   )
}

export default UnauthorizedLayout

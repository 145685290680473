import { styled } from '@mui/material/styles'
import Card from '@mui/material/Card'

export const StyledCard = styled(Card)({
    width:'15vw',
    height:'15vw',
    transition: '0.7s',
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
    fontWeight:'bold',
    fontSize:22,
    color:'#1B365C',
    '&:hover': {
        backgroundColor:'lightgray',
        cursor:'pointer',
        fontSize:24
    }
})

import axios, { AxiosError } from 'axios'

import { httpClient } from 'shared/lib/httpClient'

import { EditUserPayload, FetchUsersResult } from './types'

export function fetchUsers(userId: string): Promise<FetchUsersResult> {

   return httpClient
      .get(`users/${userId}`)
      .then((res) => {
         if (res.data) {
            return res.data
         }
         throw new Error('noData')
      })
      .catch((err) => {
         throw new Error(err.response.message)
      })
}

export function editUser(payload: EditUserPayload) {

   return httpClient
      .patch(`users/${payload.userId}`, {
         login: payload.login,
         password: payload.password,
      })
      .then((res) => ({ status: res.status }))
      .catch((err) => {
         throw new Error(err)
      })
}

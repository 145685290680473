import { Yup } from 'shared/lib'

export const addSectionSchema = Yup.object().shape({
   name: Yup.string().required(),
   color: Yup.string().required(),
})

export const addTaskSchema = Yup.object().shape({
   name: Yup.string().required(),
})

import axios, { AxiosRequestConfig, AxiosError, AxiosRequestHeaders } from 'axios'
import { loginOut } from 'shared/store/useLogin'

export const authRequest = axios.create({
   baseURL: 'https://baselinker.mikolajkaczmarek.pl/api/',
   headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Accept: 'application/json',
   },
})

export const httpClient = axios.create({
   baseURL: 'https://baselinker.mikolajkaczmarek.pl/api/',
   headers: {
      Accept: 'application/json',
   },
})

httpClient.interceptors.request.use((config: AxiosRequestConfig) => {
   const headers = {} as AxiosRequestHeaders
   const token = localStorage.getItem('accessToken')
   if (token !== null) {
      headers['X-Authorization'] = `Bearer ${token}`
   }
   return {
      ...config,
      headers: { ...config.headers, ...headers },
   }
})

let isRefreshing = false

httpClient.interceptors.response.use(
   (response) => {
      return response
   },
   async (error: Error | AxiosError<ErrorType>) => {
      if (axios.isAxiosError(error)) {
         const originalRequest = error.config
         const status = error.response?.status
         if (status === 417) {
            const refreshToken = localStorage.getItem('refreshToken') || ''
            if (!isRefreshing) {
               isRefreshing = true

               try {
                  const res = await authRequest.get<{ access_token: string }>(
                     `auth/refresh?refresh_token=${refreshToken}`
                  )
                  const token = res.data.access_token

                  localStorage.setItem('accessToken', token)

                  if (originalRequest?.headers) {
                     originalRequest.headers.Authorization = `Bearer ${token}`
                  } else {
                     originalRequest.headers = { Authorization: `Bearer ${token}` }
                  }

                  return await httpClient(originalRequest)
               } catch (err: any) {
                  if (err.response?.status === 423) {
                     console.log('wchodzi w ifa przy refreshu')
                     loginOut('/')
                        .then(() => {
                           window.location.href = '/'
                        })
                        .catch(() => {
                           window.localStorage.removeItem('refreshToken')
                           window.localStorage.removeItem('accessToken')
                           window.location.href = '/'
                        })
                  }
                  return Promise.reject(err)
               } finally {
                  isRefreshing = false
               }
            } else {
               await new Promise<void>((resolve) => {
                  const interval = setInterval(() => {
                     if (!isRefreshing) {
                        clearInterval(interval)
                        resolve()
                     }
                  }, 100)
               })
               return httpClient(originalRequest)
            }
         }
      }
      return Promise.reject(error)
   }
)

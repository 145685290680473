import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import ContainedButton from 'shared/components/Buttons/ContainedButton'
import OutlinedButton from 'shared/components/Buttons/OutlinedButton'

import { useNotificationContext } from 'shared/context/Notifications'
import { useRemoveComplaintMutation, useRemoveFileMutation } from 'shared/store/useComplaints'

interface Props {
   handleClose: () => void
   file_id: string
}

export default function RemoveFileModal({ file_id, handleClose }: Props) {
   const { addNotification } = useNotificationContext()
   const { mutate } = useRemoveFileMutation()

   function handleSubmit() {
      mutate(file_id, {
         onSuccess: () => {
            addNotification('Udało się usunąć plik', 'success')
            handleClose()
         },
         onError: () => addNotification('Nie udało się usunąć pliku', 'error'),
      })
   }

   return (
      <Box>
         <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2, mx: 3, my: 2 }}>
            <Typography variant="body1"> Czy na pewno chcesz usunąć ten plik?</Typography>
            <Box sx={{ display: 'flex', gap: 2 }}>
               <OutlinedButton onClick={() => handleClose()}>Zamknij</OutlinedButton>
               <ContainedButton variant="contained" onClick={handleSubmit}>
                  Zapisz
               </ContainedButton>
            </Box>
         </Box>
      </Box>
   )
}

import React, { useState } from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import EditIcon from '@mui/icons-material/Edit'
import Box from '@mui/material/Box'

import useLogin from 'shared/store/useLogin'
import { useUsersQuery } from 'shared/store/useUsers'
import EditUserModal from './editUserModal'

export default function Users() {
   const [openModalUuid, setOpenModalUuid] = useState<string>('')
   const { user } = useLogin()
   const { data } = useUsersQuery(user.id)

   return data ? (
      <>
         <Box sx={{ textAlign: 'center', pt: 5 }}>
            {data.users.map((item) => (
               <Typography
                  key={item.user_id}
                  variant="body1"
                  sx={{ pb: 2, '&:hover': { cursor: 'pointer', fontWeight: 'bolder' }}}
                  onClick={() => setOpenModalUuid(item.user_id)}
               >
                  {item.username}
               </Typography>
            ))}
         </Box>
         <EditUserModal userId={openModalUuid} handleClose={() => setOpenModalUuid('')} />
      </>
   ) : (
      <Box sx={{ width: '100%', textAlign: 'center', mt: 3 }}>
         <CircularProgress />
      </Box>
   )
}

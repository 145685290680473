import axios, { AxiosError } from 'axios'

import { httpClient } from 'shared/lib/httpClient'

import { AddSectionPayload, EditSectionPayload, FetchSectionsResult, RemoveSectionPayload, SectionType } from './types'

export function fetchSections(type: SectionType): Promise<FetchSectionsResult> {

   return httpClient
      .get(`sections/${type}`)
      .then((res) => {
         if (res.data) {
            return res.data
         }
         throw new Error('noData')
      })
      .catch((err) => {
         throw new Error(err.response.message)
      })
}

export function addSection(payload: AddSectionPayload) {

   return httpClient
      .post(`sections/${payload.sectionType}`, {
         name: payload.name,
         color: payload.color,
      })
      .then((res) => ({ status: res.status }))
      .catch((err) => {
         throw new Error(err)
      })
}

export function editSection(payload: EditSectionPayload) {

   return httpClient
      .patch(`sections/${payload.sectionType}/${payload.sectionId}`, {
         name: payload.name,
         color: payload.color,
      })
      .then((res) => ({ status: res.status }))
      .catch((err) => {
         throw new Error(err)
      })
}

export function removeSection(payload: RemoveSectionPayload) {

   return httpClient
      .delete(`sections/${payload.sectionType}/${payload.sectionId}`)
      .then((res) => ({ status: res.status }))
      .catch((err) => {
         throw new Error(err)
      })
}

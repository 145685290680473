import React from 'react'
import { Link } from 'react-router-dom'
import Container from '@mui/material/Container'
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'

import logo from 'assets/images/logo-large.png'

import { RootStyle } from './styles'

export default function Header() {
   return (
      <RootStyle>
         <Link to="/" style={{textDecoration:'none', color:'white',fontFamily:'didot', marginLeft:35,fontSize:32, fontWeight:'bold' }}>MOTOLI</Link>
      </RootStyle>
   )
}

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import ContainedButton from 'shared/components/Buttons/ContainedButton'
import OutlinedButton from 'shared/components/Buttons/OutlinedButton'
import { useNotificationContext } from 'shared/context/Notifications'
import { useRemoveSectionMutation } from 'shared/store/useSections'

interface Props {
   handleClose: () => void
   section_id: string
}

export default function RemoveSectionModal({ section_id, handleClose }: Props) {
   const { addNotification } = useNotificationContext()
   const { mutate } = useRemoveSectionMutation()

   function handleSubmit() {
      mutate(
         {
            sectionId: section_id,
            sectionType: 'TASK',
         },
         {
            onSuccess: () => {
               addNotification('Udało się usunąć tabele', 'success')
               handleClose()
            },
            onError: () => addNotification('Nie udało się usunąć tabeli', 'error'),
         }
      )
   }

   return (
      <Box>
         <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2, mx: 3, my: 2 }}>
            <Typography variant="body1"> Czy na pewno chcesz usunąć tą tabele?</Typography>
            <Box sx={{ display: 'flex', gap: 2 }}>
               <OutlinedButton onClick={() => handleClose()}>Zamknij</OutlinedButton>
               <ContainedButton variant="contained" onClick={handleSubmit}>
                  Zapisz
               </ContainedButton>
            </Box>
         </Box>
      </Box>
   )
}

import React, {useState, useEffect} from 'react'
import Box from '@mui/material/Box'
import { useNavigate } from 'react-router-dom'
import Card from '@mui/material/Card'
import Typography from '@mui/material/Typography'
import { Form, FormikProvider, useFormik } from 'formik'

import ContainedButton from 'shared/components/Buttons/ContainedButton'
import InputField from 'shared/components/InputField'
import useLogin from 'shared/store/useLogin'

import { LoginType } from './types'
import schema from './schema'
import { LoginPayload } from 'shared/store/useLogin/types'

export default function Homepage() {
   const [submitting, setSubmitting] = useState(false)
   const {user} = useLogin()
   const {login, error} = useLogin()
   const navigate = useNavigate()

   const formik = useFormik<LoginType>({
      initialValues: {
         login: '',
         password: '',
      },
      validationSchema: schema,
      onSubmit: handleSubmit,
   })

   const {values, isValid} = formik;

   function handleSubmit() {
      setSubmitting(true)
      const payload: LoginPayload = {
         login: values.login,
         password: btoa(values.password)
      }
      login(payload,{
         onSettled: () => setSubmitting(false)
      })
   }

   useEffect(() => {
      if (user.loginStatus === 'logged') {
         navigate('/dashboard')
      }
   }, [user])

   return (
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, alignItems: 'center', pt: '5%' }}>
         <Typography variant="h3" color="#1B365C">
            Zaloguj się
         </Typography>
         <FormikProvider value={formik}>
            <Form>
               <Card
                  sx={{
                     display: 'flex',
                     flexDirection: 'column',
                     alignItems: 'center',
                     justifyContent: 'center',
                     gap:3,
                     height: 300,
                     width: 300,
                     p: 5,
                  }}
               >
                  <InputField<LoginType> variant="standard" required name="login" label="Login" />
                  <InputField<LoginType> type='password' variant="standard" required name="password" label="Password" />
                  <ContainedButton type='submit' disabled={!isValid || submitting} variant="contained">Zaloguj</ContainedButton>
                  <span style={{color:'red'}} >{error?.message}</span>
               </Card>
            </Form>
         </FormikProvider>
      </Box>
   )
}

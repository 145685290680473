import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import DeleteIcon from '@mui/icons-material/Delete'
import IconButton from '@mui/material/IconButton'
import { useEffect, useState } from 'react'

import { useAddCommentMutation, useDeleteCommentMutation, useEditCommentMutation } from 'shared/store/useTasks'
import ContainedButton from 'shared/components/Buttons/ContainedButton'
import { useNotificationContext } from 'shared/context/Notifications'
import { useComplaintsQuery } from 'shared/store/useComplaints'
import { CommentType } from 'shared/store/useTasks/types'
import useLogin from 'shared/store/useLogin'

interface Props {
   handleClose: () => void
   complaint_id: string
}

export default function CommentsModal({ complaint_id, handleClose }: Props) {
   const { addNotification } = useNotificationContext()
   const [comment, setComment] = useState('')
   const { data } = useComplaintsQuery()
   const [comments, setComments] = useState<CommentType[]>()
   const { mutate, isLoading } = useAddCommentMutation()
   const { user } = useLogin()
   const { mutate: editMutate, isLoading: isEditLoading } = useEditCommentMutation()
   const { mutate: deleteMutate, isLoading: deleteLoading } = useDeleteCommentMutation()

   function handleAddComment() {
      mutate(
         {
            section_type: 'COMPLAINT',
            target_id: complaint_id,
            content: comment,
         },
         {
            onSuccess: () => addNotification('Twój komentarz został dodany', 'success'),
            onError: () => addNotification('Nie udało się dodać Twojego komentarza', 'error'),
         }
      )
   }

   function handleEdit(newComment: string, comment: string, comment_id: string) {
      if (newComment === comment) {
         addNotification('W Twoim komentarzu nic się nie zmieniło', 'error')
      } else {
         editMutate(
            {
               comment_id,
               content: newComment,
            },
            {
               onSuccess: () => addNotification('Twój komentarz został edytowany', 'success'),
               onError: () => addNotification('Nie udało się edytować Twojego komentarza', 'error'),
            }
         )
      }
   }

   function handleDelete(comment_id: string) {
      deleteMutate(comment_id, {
         onSuccess: () => addNotification('Twój komentarz został usunięty', 'success'),
         onError: () => addNotification('Nie udało się usunąć Twojego komentarza', 'error'),
      })
   }

   useEffect(() => {
      data?.sections.map((item) =>
         item.complaints.map((complaint) => {
            if (complaint.complaint_id === complaint_id) {
               setComments(complaint.comments)
            }
         })
      )
   }, [data])

   return (
      <Box>
         <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2, mx: 3, my: 2 }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
               <Box
                  sx={{
                     display: 'flex',
                     flexDirection: 'column',
                     maxHeight: 400,
                     padding: 5,
                     overflowY: 'scroll',
                     gap: 3,
                  }}
               >
                  {comments?.map((item) => (
                     <Box key={item.comment_id}>
                        <TextField
                           disabled={isEditLoading || (!user.admin && user.id !== item.user_id)}
                           onBlur={(e) => handleEdit(e.target.value as string, item.content, item.comment_id)}
                           multiline
                           sx={{ minWidth: 350 }}
                           defaultValue={item.content}
                           label={`${item.username} (${item.updated_date.split('T')[0]} ${
                              item.updated_date.split('T')[1].split('.')[0]
                           })`}
                        />
                        <IconButton disabled={deleteLoading} onClick={() => handleDelete(item.comment_id)}>
                           <DeleteIcon sx={{ '&:hover': { color: 'red' } }} />
                        </IconButton>
                     </Box>
                  ))}
               </Box>
               <Box sx={{ display: 'flex', alignSelf: 'center', mt: 3, gap: 1 }}>
                  <TextField
                     InputProps={{
                        rows: 4,
                        multiline: true,
                        inputComponent: 'textarea',
                     }}
                     value={comment}
                     onChange={(e) => setComment(e.target.value)}
                     disabled={isLoading}
                     label="Nowy komentarz"
                     variant="outlined"
                  />
                  <ContainedButton disabled={isLoading || !comment} variant="contained" onClick={handleAddComment}>
                     Dodaj
                  </ContainedButton>
               </Box>
            </Box>
            <Box sx={{ display: 'flex', gap: 2 }}>
               <ContainedButton variant="contained" onClick={handleClose}>
                  Zamknij
               </ContainedButton>
            </Box>
         </Box>
      </Box>
   )
}

import Box from '@mui/material/Box'
import { Form, FormikProvider, useFormik } from 'formik'

import InputField from 'shared/components/InputField'
import ContainedButton from 'shared/components/Buttons/ContainedButton'
import OutlinedButton from 'shared/components/Buttons/OutlinedButton'

import { useNotificationContext } from 'shared/context/Notifications'

import { addComplaintSchema } from '../../schema'
import { useAddComplaintMutation } from 'shared/store/useComplaints'
import { AddComplaintPayload } from 'shared/store/useComplaints/types'

interface Props {
   handleClose: () => void
   sectionId: string
}

export default function AddTaskModal({ sectionId, handleClose }: Props) {
   const { addNotification } = useNotificationContext()
   const { mutate } = useAddComplaintMutation()

   const formik = useFormik({
      initialValues: {
         name: '',
         color: '',
      },
      validationSchema: addComplaintSchema,
      onSubmit: handleSubmit,
   })
   const { values } = formik

   function handleSubmit() {
      const payload: AddComplaintPayload = {
         name: values.name,
         section_id: sectionId,
      }
      mutate(
         { ...payload },
         {
            onSuccess: () => {
               addNotification('Udało się stworzyć zadanie', 'success')
               handleClose()
            },
            onError: () => addNotification('Nie udało się stworzyć zadania', 'error'),
         }
      )
   }

   return (
      <Box>
         <FormikProvider value={formik}>
            <Form>
               <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2, px: 5, py: 2 }}>
                  <InputField label="Treść" variant="standard" name="name" required />
                  <Box sx={{ display: 'flex', gap: 2 }}>
                     <OutlinedButton onClick={() => handleClose()}>Zamknij</OutlinedButton>
                     <ContainedButton variant="contained" type="submit">
                        Zapisz
                     </ContainedButton>
                  </Box>
               </Box>
            </Form>
         </FormikProvider>
      </Box>
   )
}

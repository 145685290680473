import React from 'react'
import Box from '@mui/material/Grid'
import PersonIcon from '@mui/icons-material/Person'
import TaskIcon from '@mui/icons-material/Task'
import AssignmentIcon from '@mui/icons-material/Assignment'
import AssessmentIcon from '@mui/icons-material/Assessment'
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import { useNavigate } from 'react-router-dom'

import useLogin from 'shared/store/useLogin'

import { StyledCard } from './styles'

export default function Homepage() {
   const navigate = useNavigate()
   const {user} = useLogin()

   return (
      <Box
         sx={{
            paddingTop: 15,
            display: 'flex',
            gap: 10,
            justifyContent: 'center',
         }}
      >
         {user.admin && <Box onClick={() => navigate('users')}>
            <StyledCard>
               Użytkownicy <PersonIcon />
            </StyledCard>
         </Box>}
         <Box onClick={() => navigate('tasks')}>
            <StyledCard>
               Zadania <TaskIcon />
            </StyledCard>
         </Box>
         <Box onClick={() => navigate('complaints')}>
            <StyledCard>
               Reklamacje <AssignmentIcon />
            </StyledCard>
         </Box>
         <Box onClick={() => navigate('order')}>
            <StyledCard>
               Zamówienie <AddShoppingCartIcon />
            </StyledCard>
         </Box>
         {user.admin && <Box onClick={() => navigate('assessments')}>
            <StyledCard>
               Raporty <AssessmentIcon />
            </StyledCard>
         </Box>}
      </Box>
   )
}

import { httpClient } from 'shared/lib/httpClient'

import { AddCommentPayload, AddTaskPayload, EditCommentPayload, EditTaskPayload, FetchTasksResult } from './types'

export function fetchTasks(): Promise<FetchTasksResult> {
   return httpClient
      .get(`tasks`)
      .then((res) => {
         if (res.data) {
            return res.data
         }
         throw new Error('noData')
      })
      .catch((err) => {
         throw new Error(err.response.message)
      })
}

export function addTask(payload: AddTaskPayload) {
   return httpClient
      .post('tasks', {
         ...payload,
      })
      .then((res) => ({ status: res.status }))
      .catch((err) => {
         throw new Error(err)
      })
}

export function removeTask(task_id: string) {
   return httpClient
      .delete(`tasks/${task_id}`)
      .then((res) => ({ status: res.status }))
      .catch((err) => {
         throw new Error(err)
      })
}

export function editTask(payload: EditTaskPayload) {
   const { task_id: _, ...rest } = payload
   return httpClient
      .patch(`tasks/${payload.task_id}`, {
         ...rest,
      })
      .then((res) => ({ status: res.status }))
      .catch((err) => {
         throw new Error(err)
      })
}

export function addComment(payload: AddCommentPayload) {
   const { section_type, target_id, content } = payload
   return httpClient
      .post(`comments/${section_type}/${target_id}`, {
         content,
      })
      .then((res) => ({ status: res.status }))
      .catch((err) => {
         throw new Error(err)
      })
}

export function editComment(payload: EditCommentPayload) {
   const { comment_id, content } = payload
   return httpClient
      .patch(`comments/${comment_id}`, {
         content,
      })
      .then((res) => ({ status: res.status }))
      .catch((err) => {
         throw new Error(err)
      })
}

export function deleteComment(comment_id: string) {
   return httpClient
      .delete(`comments/${comment_id}`)
      .then((res) => ({ status: res.status }))
      .catch((err) => {
         throw new Error(err)
      })
}

import React from 'react'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import Stack from '@mui/material/Stack'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'

import { StyledFooter } from './styles'

export default function Footer() {
   return (
      <StyledFooter>
         <Typography variant='body2' sx={{color:'white'}}>© 2024 Motoli. Wszystkie prawa zastrzeżone.</Typography>
      </StyledFooter>
   )
}

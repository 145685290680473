import { Form, FormikProvider, useFormik } from 'formik'
import { CompactPicker } from 'react-color'
import Box from '@mui/material/Box'
import { useEffect } from 'react'

import ContainedButton from 'shared/components/Buttons/ContainedButton'
import OutlinedButton from 'shared/components/Buttons/OutlinedButton'
import { useNotificationContext } from 'shared/context/Notifications'
import { EditSectionPayload } from 'shared/store/useSections/types'
import { useEditSectionMutation } from 'shared/store/useSections'
import InputField from 'shared/components/InputField'

import { AddSectionPayload } from '../../types'
import { addSectionSchema } from '../../schema'

interface Props {
   handleClose: () => void
   sectionId: string
   sectionName: string
   sectionColor: string
}

export default function EditSectionModal({ sectionId, sectionName, sectionColor, handleClose }: Props) {
   const { addNotification } = useNotificationContext()
   const { mutate } = useEditSectionMutation()

   const formik = useFormik<AddSectionPayload>({
      initialValues: {
         name: '',
         color: '',
      },
      validationSchema: addSectionSchema,
      onSubmit: handleSubmit,
   })
   const { values, setFieldValue } = formik

   function handleSubmit() {
      const payload: EditSectionPayload = {
         ...values,
         sectionType: 'TASK',
         sectionId,
      }
      mutate(
         { ...payload },
         {
            onSuccess: () => {
               addNotification('Zmiany zostały zapisane', 'success')
               handleClose()
            },
            onError: () => addNotification('Nie udało się zapisać zmian', 'error'),
         }
      )
   }

   useEffect(() => {
      setFieldValue('color', sectionColor)
   }, [])

   return (
      <Box>
         <FormikProvider value={formik}>
            <Form>
               <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2, px: 5, py: 2 }}>
                  <InputField defaultValue={sectionName} label="Nazwa tabeli" variant="standard" name="name" required />
                  <CompactPicker color={values.color} onChangeComplete={(color) => setFieldValue('color', color.hex)} />
                  <Box sx={{ display: 'flex', gap: 2 }}>
                     <OutlinedButton onClick={() => handleClose()}>Zamknij</OutlinedButton>
                     <ContainedButton variant="contained" type="submit">
                        Zapisz
                     </ContainedButton>
                  </Box>
               </Box>
            </Form>
         </FormikProvider>
      </Box>
   )
}

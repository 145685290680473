import React, { useState } from 'react'
import Box from '@mui/material/Box'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import WarningAmberIcon from '@mui/icons-material/WarningAmber'
import CircularProgress from '@mui/material/CircularProgress'
import VisibilityIcon from '@mui/icons-material/Visibility'
import ChatBubbleIcon from '@mui/icons-material/ChatBubble'
import SwapHorizIcon from '@mui/icons-material/SwapHoriz'
import DeleteIcon from '@mui/icons-material/Delete'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import InfoIcon from '@mui/icons-material/Info'
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import EditIcon from '@mui/icons-material/Edit'
import MenuItem from '@mui/material/MenuItem'
import Collapse from '@mui/material/Collapse'
import Tooltip from '@mui/material/Tooltip'
import Select from '@mui/material/Select'
import Badge from '@mui/material/Badge'
import Input from '@mui/material/Input'
import Chip from '@mui/material/Chip'

import { useEditTaskMutation, useTasksQuery } from 'shared/store/useTasks'
import ContainedButton from 'shared/components/Buttons/ContainedButton'
import { useNotificationContext } from 'shared/context/Notifications'
import { EditTaskPayload } from 'shared/store/useTasks/types'
import { useUsersQuery } from 'shared/store/useUsers'
import useLogin from 'shared/store/useLogin'

import { StyledTable, StyledTableCell, StyledTableRow } from './styles'
import Modal from './components/Modal'
import { ModalInfo } from './types'

export default function Tasks() {
   const { data } = useTasksQuery()
   const { user } = useLogin()
   const { data: users } = useUsersQuery(user.id)
   const [collapse, setCollapse] = useState<string[]>([])
   const [modalInfo, setModalInfo] = useState<ModalInfo>()
   const { mutate, isLoading } = useEditTaskMutation()
   const { addNotification } = useNotificationContext()

   function handleCollapse(uuid: string) {
      if (collapse.includes(uuid)) {
         setCollapse(collapse.filter((item) => item !== uuid))
      } else {
         const tempCollapseProducts = [...collapse]
         tempCollapseProducts.push(uuid)
         setCollapse([...tempCollapseProducts])
      }
   }

   function handleEdit(payload: EditTaskPayload) {
      mutate(payload, {
         onSuccess: () => addNotification('Wpis został edytowany', 'success'),
         onError: () => addNotification('Nie udało sie zapisać zmian', 'error'),
      })
   }
   return (
      <Box>
         {data ? (
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
               {data.sections.length > 0 ? (
                  <Box
                     sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '90vw',
                        alignItems: 'flex-start',
                        gap: 3,
                        pt: 5,
                     }}
                  >
                     <ContainedButton
                        sx={{ mt: 2 }}
                        variant="contained"
                        onClick={() => setModalInfo({ modalType: 'ADD_SECTION' })}
                     >
                        Dodaj sekcje
                     </ContainedButton>
                     {data.sections.map((section) => (
                        <>
                           <Box sx={{ display: 'flex', gap: 1 }}>
                              <Typography
                                 sx={{
                                    userSelect: 'none',
                                    cursor: 'pointer',
                                    color: section.color,
                                    '&:hover': { fontWeight: 'bold' },
                                 }}
                                 onClick={() => handleCollapse(section.section_id)}
                                 variant="body1"
                              >
                                 {collapse.includes(section.section_id) ? (
                                    <ArrowForwardIosIcon
                                       sx={{
                                          transform: 'rotate(90deg)',
                                          transition: 'transform 300ms',
                                          color: section.color,
                                          fontSize: 17,
                                          mb: -0.3,
                                       }}
                                    />
                                 ) : (
                                    <ArrowForwardIosIcon
                                       sx={{
                                          transition: 'transform 300ms',
                                          color: section.color,
                                          fontSize: 17,
                                          mb: -0.3,
                                       }}
                                    />
                                 )}
                                 {`${section.name} (${section.tasks.length} Elementy)`}
                              </Typography>
                              <IconButton
                                 onClick={() =>
                                    setModalInfo({ modalType: 'EDIT_SECTION', section_id: section.section_id })
                                 }
                              >
                                 <EditIcon sx={{ fontSize: 17, mt: -0.5 }} />
                              </IconButton>
                              <IconButton
                                 onClick={() =>
                                    setModalInfo({ modalType: 'REMOVE_SECTION', section_id: section.section_id })
                                 }
                              >
                                 <DeleteIcon color="error" sx={{ fontSize: 17, mt: -0.5 }} />
                              </IconButton>
                           </Box>
                           <Collapse
                              style={{ overflowY: 'scroll', maxHeight: '600px', width: '100%' }}
                              in={collapse.includes(section.section_id)}
                              timeout="auto"
                           >
                              <Box>
                                 <StyledTable>
                                    <TableHead>
                                       <StyledTableRow>
                                          <StyledTableCell sx={{ textAlign: 'center' }}>Element</StyledTableCell>
                                          <StyledTableCell sx={{ textAlign: 'center' }}>Ludzie</StyledTableCell>
                                          <StyledTableCell sx={{ textAlign: 'center' }}>Status</StyledTableCell>
                                          <StyledTableCell sx={{ textAlign: 'center' }}>Priorytet</StyledTableCell>
                                          <StyledTableCell sx={{ textAlign: 'center' }}>Termin</StyledTableCell>
                                          <StyledTableCell sx={{ textAlign: 'center' }}>Usuń</StyledTableCell>
                                          <StyledTableCell sx={{ textAlign: 'center' }}>
                                             Przenieś zadanie
                                          </StyledTableCell>
                                          {user.admin && (
                                             <StyledTableCell sx={{ textAlign: 'center' }}>
                                                Ukryj zadanie
                                             </StyledTableCell>
                                          )}
                                          <StyledTableCell sx={{ textAlign: 'center' }} />
                                       </StyledTableRow>
                                    </TableHead>
                                    <TableBody>
                                       {section.tasks.map((item) => (
                                          <StyledTableRow>
                                             <StyledTableCell
                                                sx={{ display: 'flex', justifyContent: 'space-between', minWidth: 500 }}
                                             >
                                                {item.name}
                                                <Box>
                                                   <IconButton
                                                      onClick={() =>
                                                         setModalInfo({ modalType: 'EDIT_TASK', task_id: item.task_id })
                                                      }
                                                   >
                                                      <EditIcon sx={{ fontSize: 17 }} />
                                                   </IconButton>
                                                   <IconButton
                                                      onClick={() =>
                                                         setModalInfo({
                                                            modalType: 'COMMENTS',
                                                            task_id: item.task_id,
                                                            section_id: section.section_id,
                                                            comments: item.comments,
                                                         })
                                                      }
                                                   >
                                                      <Badge badgeContent={item.comments.length}>
                                                         <ChatBubbleIcon sx={{ fontSize: 17 }} />
                                                      </Badge>
                                                   </IconButton>
                                                </Box>
                                             </StyledTableCell>
                                             <StyledTableCell>
                                                <Select
                                                   labelId="demo-multiple-name-label"
                                                   id="demo-multiple-name"
                                                   multiple
                                                   sx={{ minWidth: 250 }}
                                                   variant="standard"
                                                   defaultValue={item.assigned_user_uuids}
                                                   onBlur={(e: any) => {
                                                      if (e.target.value) {
                                                         const payload = {
                                                            task_id: item.task_id,
                                                            assigned_user_uuids: e.target.value,
                                                         }
                                                         handleEdit(payload)
                                                      }
                                                   }}
                                                   renderValue={(selected) => (
                                                      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                         {selected.map((value) => (
                                                            <Chip
                                                               sx={{ fontWeight: 'bold' }}
                                                               key={value}
                                                               label={`${users?.users
                                                                  .find((item) => item.user_id == value)
                                                                  ?.username.split(' ')[0]
                                                                  .slice(0, 1)} ${users?.users
                                                                  .find((item) => item.user_id == value)
                                                                  ?.username.split(' ')[1]
                                                                  .slice(0, 1)}`}
                                                            />
                                                         ))}
                                                      </Box>
                                                   )}
                                                >
                                                   {users?.users.map((item) => (
                                                      <MenuItem key={item.user_id} value={item.user_id}>
                                                         {item.username}
                                                      </MenuItem>
                                                   ))}
                                                </Select>
                                             </StyledTableCell>
                                             <StyledTableCell>
                                                <Select
                                                   variant="standard"
                                                   disabled={isLoading}
                                                   defaultValue={item.status}
                                                   onChange={(e: any) => {
                                                      if (e.target.value) {
                                                         const payload = {
                                                            task_id: item.task_id,
                                                            status: e.target.value,
                                                         }
                                                         handleEdit(payload)
                                                      }
                                                   }}
                                                >
                                                   <MenuItem value="COMPLETED">Zrobione</MenuItem>
                                                   <MenuItem value="TO_VERIFY">Do weryfikacji</MenuItem>
                                                   <MenuItem value="IN_PROGRESS">W trakcie</MenuItem>
                                                   <MenuItem value="BACKLOG">Nierozpoczęte</MenuItem>
                                                </Select>
                                             </StyledTableCell>
                                             <StyledTableCell>
                                                <Select
                                                   variant="standard"
                                                   disabled={isLoading}
                                                   defaultValue={item.priority}
                                                   onChange={(e: any) => {
                                                      if (e.target.value) {
                                                         const payload = {
                                                            task_id: item.task_id,
                                                            priority: e.target.value,
                                                         }
                                                         handleEdit(payload)
                                                      }
                                                   }}
                                                >
                                                   <MenuItem value="LOW">Niski</MenuItem>
                                                   <MenuItem value="MEDIUM">Średni</MenuItem>
                                                   <MenuItem value="HIGH">Wysoki</MenuItem>
                                                   <MenuItem value="CRITICAL">
                                                      Krytyczny <WarningAmberIcon sx={{ mb: -1 }} />
                                                   </MenuItem>
                                                   <MenuItem value="UNKNOWN">-</MenuItem>
                                                </Select>
                                             </StyledTableCell>
                                             <StyledTableCell>
                                                <Input
                                                   disabled={isLoading}
                                                   defaultValue={item.due_date?.split('T')[0]}
                                                   onChange={(e: any) => {
                                                      if (e.target.value) {
                                                         const payload = {
                                                            task_id: item.task_id,
                                                            due_date: e.target.value,
                                                         }
                                                         handleEdit(payload)
                                                      }
                                                   }}
                                                   type="date"
                                                />
                                             </StyledTableCell>
                                             <StyledTableCell>
                                                <IconButton
                                                   onClick={() =>
                                                      setModalInfo({ modalType: 'REMOVE_TASK', task_id: item.task_id })
                                                   }
                                                >
                                                   <DeleteIcon />
                                                </IconButton>
                                             </StyledTableCell>
                                             <StyledTableCell>
                                                <IconButton
                                                   onClick={() =>
                                                      setModalInfo({
                                                         modalType: 'MOVE_TASK',
                                                         task_id: item.task_id,
                                                         section_id: section.section_id,
                                                      })
                                                   }
                                                >
                                                   <SwapHorizIcon />
                                                </IconButton>
                                             </StyledTableCell>
                                             {user.admin && (
                                                <StyledTableCell>
                                                   <IconButton
                                                      disabled={isLoading}
                                                      onClick={() => {
                                                         const payload = {
                                                            task_id: item.task_id,
                                                            visible: !item.visible,
                                                         }
                                                         handleEdit(payload)
                                                      }}
                                                   >
                                                      {item.visible ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                                   </IconButton>
                                                </StyledTableCell>
                                             )}
                                             <StyledTableCell>
                                                <Tooltip
                                                   title={
                                                      <div>
                                                         Data stworzenia:{' '}
                                                         {`${item.created_date.split('T')[0]} ${
                                                            item.created_date.split('T')[1].split('.')[0]
                                                         }`}
                                                         <br />
                                                         Ostatnia aktualizacja:{' '}
                                                         {`${item.updated_date.split('T')[0]} ${
                                                            item.updated_date.split('T')[1].split('.')[0] || '-'
                                                         }`}
                                                         <br />
                                                         Ostatnio aktualizował/a: {item.updated_username || '-'}
                                                         <br />
                                                         Utworzone przez: {item.username}
                                                      </div>
                                                   }
                                                >
                                                   <InfoIcon color="primary" />
                                                </Tooltip>
                                             </StyledTableCell>
                                          </StyledTableRow>
                                       ))}
                                       <StyledTableRow>
                                          <StyledTableCell
                                             onClick={() =>
                                                setModalInfo({ modalType: 'ADD_TASK', section_id: section.section_id })
                                             }
                                             colSpan={7}
                                          >
                                             + Dodaj element
                                          </StyledTableCell>
                                       </StyledTableRow>
                                    </TableBody>
                                 </StyledTable>
                              </Box>
                           </Collapse>
                        </>
                     ))}
                  </Box>
               ) : (
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, alignItems: 'center' }}>
                     <Typography variant="h6">Nie masz jeszcze żadnych tabel z zadaniami</Typography>
                     <ContainedButton variant="contained" onClick={() => setModalInfo({ modalType: 'ADD_SECTION' })}>
                        Dodaj sekcje
                     </ContainedButton>
                  </Box>
               )}
            </Box>
         ) : (
            <Box sx={{ pt: 2, width: '100%', textAlign: 'center' }}>
               <CircularProgress />
            </Box>
         )}
         <Modal
            modalInfo={modalInfo}
            actionType={modalInfo?.modalType || undefined}
            handleClose={() => setModalInfo(undefined)}
         />
      </Box>
   )
}

import axios, { AxiosError } from 'axios'

import { httpClient, authRequest } from 'shared/lib/httpClient'

import { AuthorizationResult, LoginPayload } from './types'

export function authorizeUser(payload: LoginPayload): Promise<AuthorizationResult> {
   return httpClient
      .post<AuthorizationResult>('auth/login', { login: payload.login, password: payload.password })
      .then((res) => res.data)
      .catch((err) => {
         if (err.response.status === 404 || err.response.status === 401) {
            throw new Error('Błędne dane logowania')
         } else {
            throw new Error('Błąd serwera')
         }
      })
}

export async function revokeToken(refreshToken: string) {
   return authRequest.post(`/auth/revoke?refresh_token=${refreshToken}`)
}

import { useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'

import ContainedButton from 'shared/components/Buttons/ContainedButton'
import OutlinedButton from 'shared/components/Buttons/OutlinedButton'

import { useNotificationContext } from 'shared/context/Notifications'
import { useSectionsQuery } from 'shared/store/useSections'
import { EditComplaintPayload } from 'shared/store/useComplaints/types'
import { useEditComplaintMutation } from 'shared/store/useComplaints'

interface Props {
   handleClose: () => void
   complaint_id: string
   section_id: string
}

export default function MoveComplaintModal({ complaint_id, section_id, handleClose }: Props) {
   const { addNotification } = useNotificationContext()
   const { data } = useSectionsQuery('COMPLAINT')
   const { mutate } = useEditComplaintMutation()
   const [targetSectionId, setTargetSectionId] = useState<string>()

   function handleSubmit() {
      const payload: EditComplaintPayload = {
         section_id: targetSectionId,
         complaint_id,
      }
      mutate(
         { ...payload },
         {
            onSuccess: () => {
               addNotification('Udało się zapisać zmiany', 'success')
               handleClose()
            },
            onError: () => addNotification('Nie udało się zapisać zmian', 'error'),
         }
      )
   }

   useEffect(() => {
      if (data) {
         setTargetSectionId(data.sections.filter((item) => item.section_id !== section_id)[0].section_id)
      }
   }, [])

   return (
      <Box>
         <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2, mx: 3, my: 2 }}>
            <Typography variant="body2"> Wybierz tabele docelową:</Typography>
            <Select
               defaultValue={data?.sections.filter((item) => item.section_id !== section_id)[0].section_id}
               value={targetSectionId}
               onChange={(e) => setTargetSectionId(e.target.value)}
            >
               {data?.sections
                  .filter((item) => item.section_id !== section_id)
                  .map((item) => (
                     <MenuItem key={item.section_id} value={item.section_id}>
                        {item.name}
                     </MenuItem>
                  ))}
            </Select>
            <Box sx={{ display: 'flex', gap: 2 }}>
               <OutlinedButton onClick={() => handleClose()}>Zamknij</OutlinedButton>
               <ContainedButton disabled={!targetSectionId} variant="contained" onClick={handleSubmit}>
                  Zapisz
               </ContainedButton>
            </Box>
         </Box>
      </Box>
   )
}

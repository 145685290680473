import React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

export default function Assessments() {
    return (
        <Box sx={{textAlign:'center', pt:3}}>
            <Typography variant='h6'>Work in progress</Typography>
        </Box>
    )
}
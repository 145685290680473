import AppBar from '@mui/material/AppBar'
import { styled, alpha } from '@mui/material/styles'
import Toolbar from '@mui/material/Toolbar'

export const RootStyle = styled(AppBar)(({ theme }) => ({
   boxShadow: 'none',
   position: 'sticky',
   borderBottom: '4px solid #1B365C',
   backdropFilter: 'blur(6px)',
   WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
   backgroundColor: '#1B365C',
   padding: '20px 0',
   [theme.breakpoints.up('lg')]: {
      width: '100%',
   },
}))

import React from 'react'
import { useField } from 'formik'
import TextField, { StandardTextFieldProps } from '@mui/material/TextField'
// import { AdminDataType } from 'shared/store/useProfile/types'
// import { LocationType } from 'shared/store/useLocations/types'

// interface Props extends StandardTextFieldProps {
//    name:
//       | keyof AdminDataType
//       | keyof RegisterInitialValues
//       | keyof NewLocationInitialValues
//       | keyof LocationType
//       | keyof CompanyDataSettingsInitialValues
//       | keyof SettingsType
//       | string
// }

interface Props<T> {
   name: T extends Record<string, unknown> ? keyof T : string
}

function InputField<T>({ name, ...rest }: StandardTextFieldProps & Props<T>) {
   const [field, meta] = useField<T>(name)

   return (
      <TextField
         autoComplete="new-password"
         fullWidth
         margin="none"
         error={Boolean(meta.error) && Boolean(meta.touched)}
         helperText={Boolean(meta.error) && Boolean(meta.touched) && meta.error}
         {...field}
         {...rest}
      />
   )
}

export default InputField

import Box from '@mui/material/Box'
import { Form, FormikProvider, useFormik } from 'formik'
import { CompactPicker  } from 'react-color'

import InputField from 'shared/components/InputField'
import ContainedButton from 'shared/components/Buttons/ContainedButton'
import OutlinedButton from 'shared/components/Buttons/OutlinedButton'

import { useNotificationContext } from 'shared/context/Notifications'
import { AddSectionPayload } from '../../types'
import { addSectionSchema } from '../../schema'
import { useAddSectionMutation } from 'shared/store/useSections'

interface Props{
    handleClose: () => void
}

export default function AddSectionModal({handleClose} : Props) {
    const {addNotification} = useNotificationContext()
    const {mutate} = useAddSectionMutation()

    const formik = useFormik<AddSectionPayload>({
        initialValues: {
            name: '',
            color:'',
        },
        validationSchema: addSectionSchema,
        onSubmit: handleSubmit
    })
    const {values, setFieldValue} = formik

    function handleSubmit() {
        const payload: AddSectionPayload = {
            ...values,
        }
        mutate({...payload, sectionType:'COMPLAINT'},{
         onSuccess: () =>{
            addNotification('Udało się stworzyć tabele', 'success')
            handleClose()
         },
         onError: () => addNotification('Nie udało się stworzyć tabeli', 'error')
        })
    }

   return (
      <Box>
         <FormikProvider value={formik}>
            <Form>
               <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2, px: 5, py: 2 }}>
                  <InputField label="Nazwa tabeli" variant="standard" name="name" required />
                  <CompactPicker color={values.color} onChangeComplete={(color) => setFieldValue('color', color.hex)}/>
                  <Box sx={{ display: 'flex', gap: 2 }}>
                     <OutlinedButton onClick={() => handleClose()}>Zamknij</OutlinedButton>
                     <ContainedButton variant='contained' type="submit">Zapisz</ContainedButton>
                  </Box>
               </Box>
            </Form>
         </FormikProvider>
      </Box>
   )
}
